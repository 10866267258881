import React, {useState, useRef} from "react";
import { Helmet } from "react-helmet";
import {
    Container,
    Button,
    Form
} from 'react-bootstrap';
import Navibar from "./microComponents/navbar";
import {Link} from "react-router-dom";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import emailjs from '@emailjs/browser';
import {useTranslation} from "react-i18next";

export default function Contact() {
    const [token, setToken] = useState(null);
    const [done, setDone] = useState(false);
    const form = useRef()
    const { t } = useTranslation();

    /**
     * Sends email to owner
     *
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    const onSubmit = (e) => {
        e.preventDefault();
        if (token != null) {
            emailjs.sendForm(
                'studypt',
                'template_py5bbum',
                form.current,
                process.env.REACT_APP_EmailJS
            ).then(() => {
                setDone(true)
            })
        }
    };

    return (
        <div>
            <Navibar homeArea={<Link to="/" class="text-dark text-decoration-none">{t("navbar.Home")}</Link>} />
            <Helmet>
                <title>Study in Portugal - Contact</title>
            </Helmet>
            <Container className="mt-nav">
                <h1 className="bold my-3">{done ? t("contact.resultMessageTitle") : t("contact.title")}</h1>
                {done ? <div className="text-secondary h5"> {t("contact.resultMessage")} </div> : <Form onSubmit={onSubmit} ref={form}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            type="text"
                            placeholder={t("contact.name")}
                            name="user_name"
                            className="shadow-sm"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            type="email"
                            placeholder={t("contact.email")}
                            name="reply_to"
                            className="shadow-sm"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            as="textarea"
                            placeholder={t("contact.message")}
                            rows={3}
                            name="message"
                            className="shadow-sm"
                        />
                    </Form.Group>
                    <HCaptcha
                        sitekey={process.env.REACT_APP_hCaptcha}
                        onVerify={setToken}
                    />
                    <Button variant="success" type="submit" className="shadow-sm">{t("contact.submit")}</Button>
                </Form>}
            </Container>
        </div>
    )
}
