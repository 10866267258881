import React from "react";
import { 
    Navbar,
    Container, 
    Nav, 
  } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

/**
 * Navbar
 *
 * @param props props.homeArea for the text on the left, props.className for classes around the <Link>
 * @returns {Element}
 * @constructor
 */
export default function Navibar(props) {
    const { t } = useTranslation();
    return (
        <Navbar style={{position: 'absolute', width: '100%', marginTop: '.75rem', top: 0, zIndex: 20}}>
            <Container>
                    <Nav className="me-auto text-secondary">
                        {props.homeArea}
                    </Nav>
                    <Nav>
                        <Nav.Item className="mx-2 text-dark">
                            <Link to="/institutions" className={"text-dark text-decoration-none " + (props.className || '')}>
                                {t("navbar.Universities")}
                            </Link>
                        </Nav.Item>
                        <Nav.Item className="mx-2 text-dark">
                            <Link to="/contact" className={"text-dark text-decoration-none " + (props.className || '')}>
                                {t("navbar.Contact")}
                            </Link>
                        </Nav.Item>
                    </Nav>
            </Container>
        </Navbar>
    );
}
