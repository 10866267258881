import {Link, useParams} from "react-router-dom";
import Navibar from "./microComponents/navbar";
import React, {useEffect, useRef, useState} from "react";
import Map from "react-map-gl";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import axios from "axios";
import {apiProtocol, apiurl} from "./microComponents/urls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import ProgramItem from "./microComponents/programItem";
import {userinfo} from "../state/searchResults";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import Error404 from "./error404";

/**
 * Shows number of degrees that exist at
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function DegreeBloque(props) {
    const [type, typeAlias] = props.type
    return (
        <div className={"mb-4 " + props.className}>
            <div className={"h3"} id={type}> {typeAlias} </div>
            <div className={"text-secondary"}>
                The {props.name} has over {props.programs ? props.programs.filter(data => data.DegreeType === type || data.DegreeType === (props.optional2nd || '')).length : ''} {typeAlias.toLowerCase()}{typeAlias.toLowerCase().includes('programs') ? '.' : ' programs.'}
            </div>
        </div>
    )
}

/**
 * For a university's page
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export default function Programs(props) {
    const [uniData, setUniData] = useState({})
    const [error, setError] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [language, setLanguage] = useState('Any')
    const [citizenship, setCitizenship] = useState(userinfo.getState().usersCitizenship);
    const [descent, setDescent] = useState(userinfo(state => state.descendent))
    const { u } = useParams();
    const [scrolledToType, setScrolledToType] = useState('Bachelors')
    const { t } = useTranslation();

    const div1Ref = useRef(null);
    const div2Ref = useRef(null);
    const div3Ref = useRef(null);

    useEffect(() => {
        /**
         * Scrolls the user to each program section on the page
         */
        const handleScroll = () => {
            const div1Bottom = div1Ref.current ? div1Ref.current.offsetTop : 0;
            const div2Bottom = div2Ref.current ? div2Ref.current.offsetTop : 0;
            const div3Bottom = div3Ref.current ? div3Ref.current.offsetTop : 0;
            const scrollPosition = window.pageYOffset + window.innerHeight;

            if (scrollPosition >= div3Bottom) {
                setScrolledToType('LangP');
            } else if (scrollPosition >= div2Bottom) {
                setScrolledToType('Masters');
            } else if (scrollPosition >= div1Bottom) {
                setScrolledToType('Bachelors');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    /**
     * Stores the citizenship to state
     *
     * @param {string} citizen Citizenship type
     */
    function citizenshipSwitcher(citizen) {
        // Saves citizenship status to state to be used on other pages
        userinfo.getState().saveState(citizen, 0, true)
        setCitizenship(citizen);
    }

    /**
     * Stores the language to state
     *
     * @param {((prevState: string) => string)|string} language
     */
    function languageSwitcher(language) {
        // TODO: Filter the .map in the future
        setLanguage(language)
    }

    useEffect(() => {
        axios.get(apiProtocol+apiurl+'/programs/'+u).then((res) => {
            setUniData(res.data)
            setLoading(false)
        }).catch(() => {
            setError(false)
        })
    }, [])

    /**
     * Stores whether the person is EU descendent to state
     *
     * @param descendent
     */
    function descentSwitcher(descendent) {
        setDescent(descendent);
        userinfo.getState().saveState(citizenship, descendent, true)
    }

    return (
        <>
            {
                !isLoading ?
                    error ?
                        <Error404 errorMessage={error} />
                        :
                        <>
                            <Navibar homeArea={<Link to="/" className="text-dark text-decoration-none">{t("navbar.Home")}</Link>}/>
                            <Helmet>
                                <title>{uniData.name} - Study in Portugal</title>
                                <meta name="description" content={
                                    uniData.programs.length ? (
                                        "Discover over " + uniData.programs.length + "+" + (uniData.orgType === "University" ? " university" : "") + " programs at the " + uniData.name
                                        + (uniData.programs.filter(program => program.tags.some(tag => tag.name === "English")).length > 0 ? " and " + uniData.programs.filter(program => program.tags.some(tag => tag.name === "English")).length + "+ programs in English." : ".")
                                        + " Including bachelor's and master's, such as the " + uniData.programs[0].name.replace('Degree', uniData.programs[0].DegreeType.slice(0, -1) + "")
                                    ).substring(0, 152).trim() + "..." : ""
                                } />
                            </Helmet>
                            <Map
                                initialViewState={{
                                    latitude: uniData.xCoordinates,
                                    longitude: uniData.yCoordinates,
                                    zoom: 14
                                }}
                                style={{height: '32.5vh', minHeight: 220}}
                                mapboxAccessToken={process.env.REACT_APP_mapboxAccessToken}
                                mapStyle="mapbox://styles/mapbox/streets-v12"
                            />
                            <Container className="mt-5">
                                <Row>
                                    <Col xs={12} sm={10} md={3} className="mb-1">
                                        <div className="position-relative shadow-sm">
                                            <div
                                                id="picture"
                                                className="rounded shadow-sm position-absolute bg-white"
                                                style={{
                                                    minWidth: '75%',
                                                    zIndex: 20,
                                                    backgroundImage:`url("${uniData.pictureURL}")`,
                                                    backgroundSize:'cover',
                                                    backgroundPosition: 'center',
                                                    aspectRatio: '1 / 1',
                                                    marginTop: '-85%'
                                                }}
                                            />
                                        </div>
                                        <div className={"w-md-75"}>
                                            <h5 className="bold text-center">
                                                {uniData.name}
                                            </h5>
                                        </div>
                                        <div className={"mt-4 sticky-div w-md-75"}>
                                            <a href={"#Bachelors"} className={"text-decoration-none"}>
                                                <div className={
                                                    "p-2 shadow-sm rounded mb-3 " + (scrolledToType === "Bachelors" ? "bg-success text-light" : "text-dark bg-light border")
                                                }>
                                                    Bachelor's
                                                </div>
                                            </a>
                                            <a href={"#Masters"} className={"text-decoration-none"}>
                                                <div className={
                                                    "p-2 shadow-sm rounded mb-3 " + (scrolledToType === "Masters" ? "bg-success text-light" : "text-dark bg-light border")
                                                }>
                                                    Master's
                                                </div>
                                            </a>
                                            <a href={"#LangP"} className={"text-decoration-none"}>
                                                <div className={
                                                    "p-2 shadow-sm rounded mb-3 " + (scrolledToType === "LangP" ? "bg-success text-light" : "text-dark bg-light border")
                                                }>
                                                    Others
                                                </div>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={9}>
                                        <div className={"p-3 border shadow-sm mb-5 text-dark"}>
                                            {uniData.descr}
                                            <div className={"d-flex mt-3 w-md-75"}>
                                                <div className={"me-2 flex-fill"}>
                                                    <span className={"text-secondary"}>Citizenship</span>
                                                    <select
                                                        className="form-select"
                                                        value={citizenship}
                                                        onChange={e => citizenshipSwitcher(e.target.value)}
                                                    >
                                                        <option>Non-EU citizen</option>
                                                        <option>CPLP citizen</option>
                                                        <option>EU citizen</option>
                                                    </select>
                                                </div>
                                                { citizenship == "Non-EU citizen" &&
                                                    <div className={"me-2 flex-fill"}>
                                                        <span className={"text-secondary"}>&nbsp;</span>
                                                        <select
                                                            className="form-select"
                                                            aria-label="Whether relative of EU member"
                                                            value={descent}
                                                            onChange={e => descentSwitcher(e.target.value)}
                                                        >
                                                            <option value={0}>Non-relative of EU citizen</option>
                                                            <option value={1}>Relative of EU citizen</option>
                                                        </select>
                                                    </div>
                                                }
                                                <div className={"me-2 flex-fill"}>
                                                    <span className={"text-secondary"}>Language</span>
                                                    <select
                                                        className="form-select"
                                                        aria-label="Select language"
                                                        value={language}
                                                        onChange={e => setLanguage(e.target.value)}
                                                    >
                                                        <option>Any</option>
                                                        <option>English</option>
                                                        <option>Portuguese</option>
                                                        <option>French</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div ref={div1Ref}>
                                            <DegreeBloque name={uniData.name} programs={uniData.programs} type={["Bachelors", "Bachelor's"]} />
                                        </div>
                                        {
                                            uniData ?
                                                uniData.programs.filter(data => data.DegreeType === "Bachelors" && (language === "Any" ? true : (Array.isArray(data.tags) && data.tags.length > 0)  ? data.tags.some(ldata => ldata.name === language) : language === "Portuguese")).map((results) =>
                                                    <ProgramItem
                                                        results={results}
                                                        descent={descent}
                                                        citizenship={citizenship}
                                                        languageSwitcher={languageSwitcher}
                                                        name={uniData.name}
                                                        pictureURL={uniData.pictureURL}
                                                        key={results.pk}
                                                    />
                                                )
                                            :''
                                        }
                                        <div ref={div2Ref}>
                                            <DegreeBloque optional2nd={"Integrated Masters"} name={uniData.name} programs={uniData.programs} type={["Masters", "Master's"]} className={"mt-5"} ref={div2Ref} />
                                        </div>
                                        {
                                            uniData ?
                                                uniData.programs.filter(data =>
                                                    (data.DegreeType === "Masters" || data.DegreeType === "Integrated Masters") &&
                                                        (language === "Any" ? true : (Array.isArray(data.tags) && data.tags.length > 0)  ?
                                                            data.tags.some(ldata => ldata.name === language) : language === "Portuguese")
                                                ).sort((a, b) => {
                                                    if (a.DegreeType === "Integrated Masters" && b.DegreeType === "Masters") {
                                                        return -1; // a comes before b
                                                    } else if (a.DegreeType === "Masters" && b.DegreeType === "Integrated Masters") {
                                                        return 1; // b comes before a
                                                    } else {
                                                        return 0; // no sorting needed
                                                    }
                                                }).map((results) =>
                                                        <ProgramItem
                                                            results={results}
                                                            descent={descent}
                                                            citizenship={citizenship}
                                                            languageSwitcher={languageSwitcher}
                                                            name={uniData.name}
                                                            pictureURL={uniData.pictureURL}
                                                        />
                                                    )
                                                :''
                                        }
                                        <div ref={div3Ref}>
                                            <DegreeBloque name={uniData.name} programs={uniData.programs} type={["LangP", "Language Programs"]} className={"mt-5"} />
                                            {
                                                uniData ?
                                                    uniData.programs.filter(
                                                            data => data.DegreeType === "LangP" &&
                                                                (language === "Any" ? true :
                                                                    (Array.isArray(data.tags) && data.tags.length > 0) ?
                                                                        data.tags.some(ldata => ldata.name === language) : language === "Portuguese")
                                                    ).map((results) =>
                                                        <ProgramItem
                                                            results={results}
                                                            descent={descent}
                                                            citizenship={citizenship}
                                                            languageSwitcher={languageSwitcher}
                                                            name={uniData.name}
                                                            pictureURL={uniData.pictureURL}
                                                        />
                                                    )
                                                    :''
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    :
                    <div className="position-absolute w-100 h-100 bg-light d-flex justify-content-center align-items-center" style={{zIndex: "2000"}}>
                        <Spinner animation="border" role="status" style={{color:'grey'}} className="d-flex align-items-center">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
            }
        </>
    )
}