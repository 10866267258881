import React, {useRef, useState} from 'react';
import {Button, Card, Form, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faClock} from "@fortawesome/free-solid-svg-icons";
import CountryList from "./countryList";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {handleEventClick} from "./handleEventClick";
import {Apply} from "../apis/apply";

function ProgramPageApply(props) {
    const [applyClicked, setApplyClicked] = useState(false);
    const [marketingPerms, setMarketingPerms] = useState(true);
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const [token, setToken] = useState(null);
    const [TOS, setTOS] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        middleNames: "",
        lastName: "",
        country: "",
        citizenship: "",
        email: ""
    });

    const { t } = useTranslation();
    const form = useRef();

    const onSubmit = async (e) => {
        e.preventDefault();
        await Apply(
            formData.email,
            formData.firstName,
            formData.lastName,
            formData.middleNames,
            formData.country,
            formData.citizenship,
            marketingPerms,
            token
        )
        handleEventClick(props.values.university_data.name, "Apply", "Submit")
        setLoading(false);
        setComplete(true);
    };

    if (props.values.applyUrl) {
        return (
            <div className={"my-4"}>
                <a href={props.values.applyUrl} target={"_blank"} className={"text-decoration-none"} onClick={() => handleEventClick(props.values.applyUrl, "Link", "ApplyLink")}>
                    <Card className={"px-4 py-3 shadow text-light border-0 rounded"} style={{background: "#178150"}}>
                        <div className={"d-flex justify-content-between pointer"}>
                            <h5>
                                {t("program.Apply")}
                            </h5>
                            <div className={"d-flex align-items-center"}>
                                <FontAwesomeIcon icon={faArrowRight} className={"h4 mb-0"}/>
                            </div>
                        </div>
                    </Card>
                </a>
            </div>
        );
    }

    if (props.values.applyEmail && !complete) {
        return (<></>)
        return (
            <div className={"my-4"}>
                <Card
                    className={"px-4 pt-3 shadow text-light border-0 rounded " + (!applyClicked ? "pb-3" : "pb-4")}
                    style={{background: "#178150"}}
                    onClick={() => setApplyClicked(true)}
                >
                { !applyClicked ?
                        <div className={"d-flex justify-content-between pointer"}>
                            <h5>
                                {t("apply.quickApply")}
                            </h5>
                            <div className={"d-flex align-items-center"}>
                                <FontAwesomeIcon icon={faArrowRight} className={"h4 mb-0"} />
                            </div>
                        </div>
                        :
                        <Form onSubmit={onSubmit} ref={form}>
                            <div>
                            <div className={"mb-4"}>
                                <h5>
                                    {t("apply.quickApply")}
                                </h5>
                                <div>
                                    {t("apply.description")}
                                </div>
                            </div>
                            <div className={"mb-4"}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        placeholder={t("apply.firstName")}
                                        name="user_name"
                                        className="shadow-sm"
                                        onChange={(e) => setFormData({...formData, ["firstName"]: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        placeholder={t("apply.middleNames")}
                                        name="user_name"
                                        className="shadow-sm"
                                        onChange={(e) => setFormData({...formData, ["middleNames"]: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        placeholder={t("apply.lastName")}
                                        name="user_name"
                                        className="shadow-sm"
                                        onChange={(e) => setFormData({...formData, ["lastName"]: e.target.value })}
                                    />
                                </Form.Group>
                            </div>
                            <Form.Select className="mb-3" onChange={(e) => setFormData({...formData, ["country"]: e.target.value })}>
                                <option value="value">
                                    {t("apply.SelectYourCountry")}
                                </option>
                                <CountryList />
                            </Form.Select>
                            <Form.Select className="mb-3" onChange={(e) => setFormData({...formData, ["citizenship"]: e.target.value })}>
                                <option value="value">
                                    {t("apply.WhatsCitizenship")}
                                </option>
                                <option value="nonEU">
                                    {t("citizenship.nonEU")}
                                </option>
                                <option value="EU">
                                    {t("citizenship.EU")}
                                </option>
                                <option value="CPLP">
                                    {t("citizenship.CPLP")}
                                </option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="formBasicEmail" onChange={(e) => setFormData({...formData, ["email"]: e.target.value })}>
                                <Form.Control
                                    type="email"
                                    placeholder={t("apply.Email")}
                                    name="reply_to"
                                    className="shadow-sm"
                                />
                            </Form.Group>
                            <div className={"d-flex mb-3"}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="TOS"
                                        checked={TOS}
                                        onClick={() => setTOS(!TOS)}
                                    />
                                    <label className="form-check-label" htmlFor="TOS">
                                        {t("apply.TOS")}
                                    </label>
                                </div>
                                <div className="form-check ms-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="marketing"
                                        checked={marketingPerms}
                                        onClick={() => setMarketingPerms(!marketingPerms)}
                                    />
                                    <label className="form-check-label" htmlFor="marketing">
                                        {t("apply.marketing")}
                                    </label>
                                </div>
                            </div>
                            <div className={TOS ? "d-block mb-3" : "d-none"}>
                                <HCaptcha
                                    sitekey={process.env.REACT_APP_hCaptcha}
                                    onVerify={setToken}
                                />
                            </div>
                            <Button variant="light" className={"w-100"} disabled={(token && TOS) || !loading} onClick={() => setLoading(true)}>
                                {loading ? <Spinner animation="border" role="status">
                                    <span className="visually-hidden">{t("apply.Loading")}</span>
                                </Spinner> : <> {t("program.Submit")} <FontAwesomeIcon icon={faArrowRight} className={"mb-0"} /> </>}
                            </Button>
                        </div>
                        </Form>
                    }
                </Card>
            </div>
        );
    }

    if (complete) {
        return (
            <div>
                <FontAwesomeIcon icon={faClock} className={"h4 mb-0 d-block"} />
                {t("apply.confirm")}
            </div>
        )
    }
}

export default ProgramPageApply;