/**
 * Checks if object is empty
 *
 * @param obj
 * @returns {boolean}
 */
export function isEmpty(obj) {
    if (typeof obj === 'object') {
        return Object.keys(obj).length === 0
    } else {
        return false
    }
}