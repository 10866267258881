import axios from "axios";
import {apiProtocol, apiurl} from "../microComponents/urls";
import {query} from "../../state/searchResults";
import {sleep} from "../microComponents/sleep";
import {SupportedLanguages} from "../microComponents/supportedLanguages";
import i18n from "i18next";


/**
 * Search API that returns program info without start, end, lang, or degree parameters. Gives broad results based on query.
 *
 * @param {string} search String user query
 */
export async function SimpleSearchAPI(search) {
    const langFormatter = i18n.language.includes("-") ? i18n.language.split('-')[0] : i18n.language;
    const userLanguage = SupportedLanguages().includes(langFormatter) ? "/" + langFormatter : "";
     // Returns whether the search succeeded or not
    try {
        let res = await axios.get(apiProtocol + apiurl + '/search/' + search.trim() + '/0/10/all/all' + userLanguage);

        query.setState({
            search: search,
            results: res.data,
            language: i18n.language
        });

        // Gives state a bit to save before moving to search page and requesting the data
        await sleep(200);
    } catch (e) {
        // Don't expose the error to the user in prod
        throw new Error(process.env.NODE_ENV === "development" ? e.message: 'Search failed');
    }
}