import create from 'zustand';
import {persist} from "zustand/middleware";
import {mountStoreDevtool} from "simple-zustand-devtools";

export const query = create(persist((set, get) => ({
    search: "",
    results: [],
    images: []
}),
    {
        name: "query"
    }
))


export const userinfo = create(persist((set, get) => ({
    descendent: 0,
    citizen: 0,
    cplp: 0,
    language: "",
    fromSearch: false,
    saveState: (citizenship, descent, fromHome) => set((state) => ({
        citizen: citizenship === "EU citizen" ?  1 : 0,
        cplp: citizenship === "CPLP citizen" ?  1 : 0,
        descendent: descent,
        fromSearch: fromHome
    })),
    usersCitizenship: () => {
        let userCitizenship;
        let state = userinfo.getState();
        if (state.descendent === 1 || state.citizen === 1) {
            userCitizenship = "EU citizen";
        } else if (state.cplp === 1) {
            userCitizenship = "CPLP citizen";
        } else {
            userCitizenship = "Non-EU citizen";
        }
        return userCitizenship
    },
    usersCitizenshipDBFormat: () => {
        // This is used for filtering deadlines
        let userCitizenship;
        let state = userinfo.getState();
        if (state.descendent === 1 || state.citizen === 1) {
            userCitizenship = "local";
        } else {
            // Both CPLP and int have the same deadlines
            userCitizenship = "international";
        }
        return userCitizenship
    }
}),
    {
        name: "userinfo"
    }
))


if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('query', query);
}
if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('userinfo', userinfo);
}
