import {Col, Row} from "react-bootstrap";
import React, {useRef} from "react";
import {Link} from "react-router-dom";
/*
const MyMap = memo(({ x, y }) => {
    return <Map
        initialViewState={{
            zoom: 5,
            longitude: y,
            latitude: x
        }}
        style={{maxHeight: '10rem'}}
        scrollZoom={false}
        dragRotate={false}
        touchRotate={false}
        keyboard={false}
        mapboxAccessToken={process.env.REACT_APP_mapboxAccessToken}
        mapStyle="mapbox://styles/mapbox/streets-v12"
    >
        <Marker longitude={y} latitude={x} anchor="bottom">
            <img src={"./marker-editor.svg"} style={{width: '1.75rem'}} />
        </Marker>
    </Map>;
}, (prevProps, nextProps) => {
    return prevProps.x === nextProps.x && prevProps.y === nextProps.y
});
*/
/**
 * MapBox static map image
 *
 * @param props props.city, props.name, props.x, props.y, props.description
 * @returns {Element}
 * @constructor
 */
export default function UniversityListItem(props) {
    const ref = useRef(null)
    return (
        <Row className={"rounded overflow-hidden border shadow-sm mb-4"}>
            <Col className={"px-0 map"} xs={3} ref={ref}>
                <img
                    src={"https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/url-https%3A%2F%2Fwww.studyportugal.pt%2Fmarker-editor.png("+props.y+","+props.x+")/"+props.y+","+props.x+","+((props.x.slice(0, 2).includes('40')|| props.x.slice(0, 2).includes('38') || props.x.slice(0, 2).includes('39') || props.x.slice(0, 2).includes('41')) ? '5' : '6')+"/"+(ref.current ? ref.current.offsetWidth : '')+"x"+(ref.current ? ref.current.offsetHeight : '')+"?access_token="+process.env.REACT_APP_mapboxAccessToken}
                    alt={props.name +" location map"}
                />
            </Col>
            <Col className={"py-3"}>
                <Link to={"/institutions/"+props.name+"/programs"}>
                    <div className={"w-100 h-100"}>
                        <img src={props.pic} alt={props.name+" university logo"} style={{width: '4rem'}} className={"border rounded mb-2 d-inline-block"} />
                        <div className={"d-inline-block"}>
                            <div className={"h5 text-dark"}>
                                {props.name}
                            </div>
                            <div className={"text-dark"}>{props.name.includes(props.city) ? '' : props.city}</div>
                            <div
                                className={"text-secondary maxLine-2"}
                                style={{
                                    lineHeight: '1.52'
                                }}>
                                {props.description}
                            </div>
                        </div>
                    </div>
                </Link>
            </Col>
        </Row>
    )
}