import React, { useState, useEffect } from "react";
import { 
    Container,
    Button, 
    InputGroup, 
    FormControl, 
    Row,
    Col,
    Spinner,
    Collapse
  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import {query, userinfo} from '../state/searchResults';
import 'font-awesome/css/font-awesome.css';
import Navibar from "./microComponents/navbar";
import '../css/search.css';
import { Helmet } from "react-helmet";
import '../css/global.css';
import {Link, useParams} from "react-router-dom";
import {useBottomScrollListener} from "react-bottom-scroll-listener";
import ProgramItem from "./microComponents/programItem";
import {ComplexSearchAPI} from "./apis/complexSearch";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "./microComponents/capitalizeFirstLetter";


export default function Search() {
    // s = Search query, l = language, p = program/degree type
    const { s, l, p } = useParams();
    const [totalPosts, setTotalPosts] = useState(false);
    const [loading, setLoading] = useState(0);
    const [language, setLanguage] = useState(l ? l : "Any");
    const [degree, setDegree] = useState(p ? p : "Any");
    const [citizenship, setCitizenship] = useState();
    const [gettingMore, setGettingMore] = useState(false)
    const [searchQuery, setSearchQuery] = useState(
        query(state => state.search && (s ? s === state.search : true) ? state.search : s)
    );
    const [descent, setDescent] = useState(userinfo(state => state.descendent));
    const [results, setResults] = useState(query(state => state.results));
    const [error, setError] = useState("");
    const [open, setOpen] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);
    const { t } = useTranslation();

    useEffect(() => {
        // Grabs and interprets users citizenship status from state
        let userState = userinfo.getState();

        if (userState.citizen === 1) {
            userState = "EU citizen"
        } else if (userState.cplp === 1) {
            userState = "CPLP citizen"
        } else if (userState.citizen === 0) {
            userState = "Non-EU citizen"
        }

        setCitizenship(userState)
        requestData("",0, 10)

        // Determining page width
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])


    useEffect(() => {
        if (width < 768) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, [width]);

    // useEffect used to prevent spamming enter, wasting server resources
    useEffect(() => {
        requestData("", 0,10)
    }, [degree, language])

    /**
     * Performs a complex search of programs filtering by degree and language
     *
     * @param e Event
     * @param start Index start
     * @param end Index end
     * @param concat Whether to concat the additional programs to the existing ones or replace
     * @returns {Promise<any|boolean>}
     */
    async function requestData(e = "", start= 0, end=10, concat=false) {
        setLoading(1);
        if (e !== "") {
            e.preventDefault();
        }
        let lang = (language === "Any") ? "all" : language;
        let degr = (degree === "Any") ? "all" : degree;

        return await ComplexSearchAPI(setResults, citizenship, descent, searchQuery, lang, degr, start, end, concat).then((res) => {
            setLoading(0);
            if (concat) {
                return res
            }
        }).catch((e) => {
            console.log("Error", e)
            setError(e.message);
        });
    }

    /**
     * Stores the width of window to state
     */
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    /**
     * Sets the language to state to filter
     *
     * @param {((prevState: (string|string)) => (string|string))|string|string} language
     */
    function languageSwitcher(language) {
        // filter the .map in the future
        setLanguage(language)
    }

    /**
     * Sets the degree to state to filter
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} e
     */
    function changeDegree(e) {
        setDegree(e.target.value)
    }

    /**
     * Sets a users citizenship status to state
     *
     * @param {string|((prevState: undefined) => undefined)} citizen
     */
    function citizenshipSwitcher(citizen) {
        setCitizenship(citizen);
        // Saves citizenship status to state to be used on other pages
        userinfo.getState().saveState(citizen, 0, true)
    }

    /**
     * Sets whether a user is a descendent of an EU citizen, which gives lower tuition costs in Portugal
     *
     * @param {number} descendent Whether user is descendent (As binary 0/1)
     */
    function descentSwitcher(descendent) {
        setDescent(descendent);
        // Saves citizenship status to state to be used on other pages
        userinfo.getState().saveState(citizenship, descendent, true)
    }

    /**
     * Grabs more programs as the user scrolls down
     *
     * @param {number} start Index start
     * @param {number} end Index end
     */
    function resultFetch(start, end) {
        requestData("", start, end, true).then((resultData) => {
            if (resultData) {
                let newResults = resultData;
                if ((end - start) === 5) {
                    newResults = results.concat(resultData);
                    // If new data given matches amount requested
                    let hitLimit = (start + resultData.length) === end
                    // If there might be more data
                    setTotalPosts(!hitLimit)
                }
                query.setState({results: newResults })
                setResults(newResults)
                setLoading(0);
                setGettingMore(false)
            }
        })
    }

    /**
     *  Checks whether there is possibly more programs, then requests the appropriate amount of more programs
     */
    function refresh() {
        if (results.length >= 10 && !totalPosts) {
            resultFetch(results.length, (results.length+5))
            setGettingMore(true)
        }
    }

    // Checks if user is at bottom of page, then runs refresh
    useBottomScrollListener(refresh);

    return (
        <>
            <Helmet>
                <title>Study Portugal - {capitalizeFirstLetter(searchQuery)} results </title>
            </Helmet>
            <Navibar homeArea={<Link to="/" className="text-dark text-decoration-none">{t("navbar.Home")}</Link>} />
            <Container>
                <form onSubmit={requestData}>
                    <Row className="mt-nav">
                        <Col md={3} xs={12}>
                            <div className="bg-success position-relative mb-4 w-100" 
                                style={{minHeight:'fit-content', marginTop: '10px', float: 'left', marginLeft: '-5px'}}
                            >
                                <div className="bg-light w-100 px-4 py-3" style={{marginTop: '-10px', marginLeft: '10px', marginBottom: '10px'}}> 
                                    <div className="pb-1 pointer" onClick={e => setOpen(!open)} style={{lineHeight:'2.25'}}>
                                        <span className="h5">
                                            {t("search.Filters")}
                                        </span> 
                                        { 
                                            (width < 768) ? open ? <FontAwesomeIcon className="mx-2" icon={faChevronUp} />
                                            :
                                            <FontAwesomeIcon className="mx-2" icon={faChevronDown} /> : ""
                                        }
                                    </div>
                                    <Collapse in={open}>
                                        <div>
                                            <div className="mb-2">
                                                {t("search.Citizenship")}
                                            </div>
                                            
                                            <select className="form-select mb-2" aria-label="Whether relative of EU member" value={citizenship} onChange={e => citizenshipSwitcher(e.target.value)}>
                                                <option value={"Non-EU citizen"}>{t("citizenship.nonEU")}</option>
                                                <option value={"CPLP citizen"}>{t("citizenship.CPLP")}</option>
                                                <option value={"EU citizen"}>{t("citizenship.EU")}</option>
                                            </select>
                                            { citizenship == "Non-EU citizen" &&
                                                <select className="form-select" aria-label="Whether relative of EU member" value={descent} onChange={e => descentSwitcher(e.target.value)}>
                                                    <option value={0}>{t("search.notRelativeShort")}</option>
                                                    <option value={1}>{t("search.relative")}</option>
                                                </select>
                                            }
                                            <div className="my-2">
                                                {t("search.Language")}
                                            </div>
                                            <select className="form-select" aria-label="Select language" value={language} onChange={e => setLanguage(e.target.value)}>
                                                <option value={"Any"}>{t("languageOptions.Any")}</option>
                                                <option value={"English"}>{t("languageOptions.English")}</option>
                                                <option value={"Portuguese"}>{t("languageOptions.Portuguese")}</option>
                                                <option value={"French"}>{t("languageOptions.French")}</option>
                                            </select>
                                            <div className="my-2">
                                                {t("search.Degree")}
                                            </div>
                                            <select className="form-select" aria-label="Select degree" value={degree} onChange={e => changeDegree(e)}>
                                                <option value={"Any"}>{t("languageOptions.Any")}</option>
                                                <option value={"Bachelor's"}>{t("degreeTypes.Bachelors")}</option>
                                                <option value={"Master's"}>{t("degreeTypes.Masters")}</option>
                                                {/*<option>PHd</option>*/}
                                                <option value={"langP"}>{t("degreeTypes.langProg")}</option>
                                            </select>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </Col>
                        <Col md={9} xs={12} style={{minHeight: '20rem'}}>
                            <InputGroup className="mb-3 shadow-sm rounded border">
                                <FormControl
                                    placeholder={t("home.searchADegree")}
                                    aria-label="Search a degree"
                                    aria-describedby="searchbtn"
                                    className="p-2 border-0"
                                    name="search"
                                    value={searchQuery !== 'All' ? searchQuery : ''}
                                    onChange={e => setSearchQuery(e.target.value)}
                                />
                                <Button variant="secondary" className="border-0 bg-white" type="submit">
                                    { loading ? 
                                        <Spinner animation="border" role="status" style={{color:'grey'}} className="d-flex align-items-center">
                                            <span className="visually-hidden">{t("apply.Loading")}</span>
                                        </Spinner>
                                    :
                                        <FontAwesomeIcon icon={faSearch} flip="horizontal" color="grey"/>
                                    }
                                </Button>
                            </InputGroup>
                            {error}
                            {results &&
                                results.map((results) =>
                                    <ProgramItem
                                        results={results}
                                        descent={descent}
                                        citizenship={citizenship}
                                        languageSwitcher={languageSwitcher}
                                        name={results.university_data.name}
                                        pictureURL={results.university_data.pictureURL}
                                    />
                                )
                            }
                            {
                                gettingMore ?
                                    <div className={"w-100 d-flex justify-content-center"}>
                                        <Spinner animation="border" role="status" style={{color:'grey'}} className="d-flex mt-3 align-items-center">
                                            <span className="visually-hidden">{t("apply.Loading")}</span>
                                        </Spinner>
                                    </div>:
                                    ""
                            }
                        </Col>
                    </Row>
                </form>
            </Container>
        </>
    );
}
