import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
    Row,
    Col,
    Container,
    Card,
    Spinner, Button
} from 'react-bootstrap';
import Navibar from "./microComponents/navbar";
import '../css/global.css';
import '../css/program.css';
import Map from 'react-map-gl';
import {useLocation, useParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import 'font-awesome/css/font-awesome.css';
import { Link } from "react-router-dom";
import axios from "axios";
import {query, userinfo} from '../state/searchResults';
import {isEmpty} from "./microComponents/isEmpty";
import {Popup} from "./microComponents/useTypePopup";
import {apiProtocol, apiurl} from "./microComponents/urls";
import mapboxgl from "mapbox-gl";
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {similarPrograms} from "./apis/similarPrograms";
import {useTranslation} from "react-i18next";
import ProgramPageApply from "./microComponents/programPageApply";
import {handleEventClick} from "./microComponents/handleEventClick";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import Error404 from "./error404";
import {capitalizeFirstLetter} from "./microComponents/capitalizeFirstLetter";

export default function Program(props) {
    const { t, i18n } = useTranslation();
    const loc = useLocation().pathname.split('/');
    const location = props.baseUrl
        ? loc.filter(function(e) { return e !== props.baseUrl.substring(1) })
        : loc;
    const { university, degree } = useParams();
    const [userType, setUserType] = useState(userinfo.getState().usersCitizenship);
    const [housing, setHousing] = useState([])
    const [values, setValues] = useState({
        programName: degree,
        uniName: university,
        tuition: "",
        languages: "",
        link: "",
        years: "",
        startDate: "",
        pic: "",
        deadlines: {},
    });

    const [isHovering, setIsHovering] = useState(false);
    const [isLoading, setLoading] = useState(0);
    const [deadlineData, setDeadlineData] = useState({})
    const [errorMessage, setErrorMessageMessage] = useState(false)
    const [suggestedPrograms, setSuggestedPrograms] = useState([]);

    /**
     * Converts the client formatting of the degree to the format the db uses
     *
     * @param degreeName Name of the degree in client formatting
     * @returns {*|string} Degree in db format
     * @constructor
     */
    function DegreeTypeDeterminer(degreeName) {
        if (degreeName.includes('Foundation') && degreeName.includes('Program')) {
            return 'Foundation Year'
        }

        degreeName = degreeName.replace(/%20/g, ' ')
        if (degreeName.includes('Integrated Masters')) {
            return 'Integrated Masters'
        } else if (
            (
                degreeName.toLowerCase().includes('portuguese') && degreeName.toLowerCase().includes('course'))
            || degreeName.toLowerCase().includes('portuguese as a foreign language')
            || degreeName.toLowerCase().includes('portuguese as a second language')
        ) {
            return 'LangP'
        } else {
            return degreeName.replace(/%20/g, ' ').split(' ')[0].trim()
        }
    }

    useEffect(() => {
        setLoading(0)
        console.log(location, loc[1], props.baseUrl)
        let uni = location[1]
        let degreeName = location[2]
        let degreeType = DegreeTypeDeterminer(degreeName)

        // Do not remove the degree from the title if it's foundation year
        if (degreeName == "Foundation Year") {
            degreeName = degreeName.replace(/%20/g, ' ').replace(degreeType, '');
        }

        degreeName = degreeName.replace(/%20/g, ' ').trim()

        // Do not add the word "Degree" to the name if LangP or Foundation Year
        if (degreeType !== "LangP" && degreeType !== "Foundation Year") {
            degreeName = degreeName.split(' ').slice(1);
            degreeName = "Degree "+degreeName.join(' ')
        }

        uni = uni.replace(/%20/g, ' ').trim()
        let searchData = query.getState().results;

        // Checking persisted state for data related to the program, so it doesn't need to re-request, wasting bandwidth
        const foundCourse = searchData.find((course) => {
            return course.name === degreeName &&
                course.DegreeType === degreeType &&
                course.university_data.name === uni;
        });

        // If nothing found in persisted state
        if (!foundCourse) {
            let formattedLang = ""
            if (i18n.language !== "EN") {
                formattedLang = "/" + i18n.language
            }
            // Retrieves all data related to the program
            axios.get(apiProtocol+apiurl+'/program/'+uni+"/"+degreeType+"/"+degreeName+formattedLang).then(async (response) => {
                // Save housing and program data
                setValues(response.data.program)
                setLoading(1)
                console.log("progdata", response.data.program)

                axios.get(apiProtocol+apiurl+'/housing/'+response.data.program.city, {}).then((res) => {
                    setHousing(res.data)
                })

                await RetrieveSuggestedPrograms(degreeName, degreeType, response.data.program.id, response.data.program.tags)
            }).catch(()=> {
                setErrorMessageMessage(true)
            });
        } else {
            // Retrieves the matching object with program data in the array of objects
            let program = searchData.filter(obj => obj.name === degreeName && obj.DegreeType === degreeType && obj.university_data.name === uni)[0]

            // Stores the most recent deadline for the user citizenship type separately
            // TODO: Verify this is showing those of descent properly
            setDeadlineData(program.deadlines.filter(obj => obj.studentType === userinfo.getState().usersCitizenshipDBFormat)[0])
            setValues(program)
            setLoading(1)

            axios.get(apiProtocol+apiurl+'/housing/'+program.city, {}).then((res) => {
                setHousing(res.data)
            }).then(async () => {
                await RetrieveSuggestedPrograms(degreeName, degreeType, program.id, program.tags)
            })
        }
    }, [university + degree]);

    async function RetrieveSuggestedPrograms(degreeName, degreeType, pk, tags) {
        setSuggestedPrograms(
            await similarPrograms(degreeName, degreeType, pk, tags, i18n.language)
        )
    }

    /**
     * Checks if user is hovering, sets to state if true & not already set so
     */
    const handleMouseOver = () => {
        if (isHovering == false) {
            setIsHovering(true);
        }
    };

    /**
     * Checks if user is hovering, sets to state if false & not already set so
     */
    const handleMouseOut = () => {
        if (isHovering == true) {
            setIsHovering(false);
        }
    };

    /**
     * Shows the next immediate deadline in the information card
     *
     * @returns {Element}
     * @constructor
     */
    const SmallDeadline = () => {
        let latestDate;
        let isoDate;
        let mKey = ""
        let titleObj = {
            'firstCallEnd': t('deadlineLabels.initDeadlineSmall'),
            'secondCallEnd': t('deadlineLabels.initDeadlineSmall'),
            'thirdCallEnd': t('deadlineLabels.thirdSmall'),
            'SpecialCallEnd': t('deadlineLabels.fourthSmall'),
            'SpecialCall2End': t('deadlineLabels.fifthSmall')
        }
        if (typeof values.deadlines !== 'undefined') {
            // Preprocessing for non-search user
            if (isEmpty(deadlineData)) {
                setDeadlineData(values.deadlines.filter(obj => obj.studentType === 'international')[0])
            }

            // Gets date closest to today that has a key that ends with "end"
            const currentDate = new Date();
            if (deadlineData) {

                latestDate = Object.keys(deadlineData)
                    .filter(key => key.endsWith('End'))
                    .map(key => deadlineData[key])
                    .filter(date => date !== null)
                    .map(date => new Date(date))
                    .filter(date => date > currentDate)
                    .sort((a, b) => a - b)[0];
            }

            if (latestDate) {
                isoDate = latestDate.toISOString().substring(0, 10)

                // Matches that key with a title
                for (const key in deadlineData) {
                    if (deadlineData.hasOwnProperty(key) && deadlineData[key] === isoDate) {
                        mKey = key
                    }
                }
            }
        }

        return (
            <div className="position-relative">
                <h5>
                    {latestDate != undefined ?
                        <>
                            <div className={"d-none d-sm-block"}>
                                {latestDate != 'undefined' ? latestDate.toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" }) : t("program.Unknown")}
                            </div>
                            <div className={"d-sm-none"}>
                                {latestDate != 'undefined' ? latestDate.toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" }) : t("program.Unknown")}
                            </div>
                        </>
                        : t("program.Unknown") }
                </h5>
                <span className="text-muted mobile-small">{latestDate != undefined ? mKey !== "" ? titleObj[mKey] : "" : ""} {t('program.deadline')}</span>
                {latestDate != undefined ? <FontAwesomeIcon icon={faInfoCircle} onClick={handleMouseOver} className="position-absolute" style={{top:0, marginTop:'-5px', marginRight: '-15px', right: '0px'}} /> : "" }
            </div>
        );
    }

    /**
     * Shows the deadlines for the most recent school cycle in the data as a popup
     *
     * @returns {Element}
     * @constructor
     */
    const ExtendedDeadlines = () => {
        let firstEnd = ""
        let secondEnd = ""
        let thirdEnd = ""
        let specialEnd = ""
        if (deadlineData) {
            firstEnd = deadlineData.firstCallEnd?.toString();
            secondEnd = deadlineData.secondCallEnd?.toString();
            thirdEnd = deadlineData.thirdCallEnd?.toString();
            specialEnd = deadlineData.SpecialCallEnd?.toString();
        }

        return (
            <div className="rounded shadow border bg-white position-absolute" id="deadlinesDropdown" style={{width:'120%', marginLeft: '-20px', marginRight: '-20px', zIndex: 5}}>
                <div className="px-4 py-3 bg-light-hover">
                    <h5>
                        { (firstEnd != "0000-00-00" && firstEnd) ?
                            new Date(firstEnd).toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" }) : t("program.Unknown")
                        }
                    </h5>
                    <span className="text-muted">{t("deadlineLabels.initDeadline")}</span>
                </div>
                {secondEnd ?
                    <div className="px-4 py-3 bg-light-hover">
                        <h5>
                            { (secondEnd != "0000-00-00" && secondEnd) ?
                                new Date(secondEnd).toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" }) : t("program.Unknown")
                            }
                        </h5>
                        <span className="text-muted">{t("deadlineLabels.second")}</span>
                    </div>
                    : ""}
                {thirdEnd ?
                    <div className="px-4 py-3 bg-light-hover">
                        <h5>
                            { (thirdEnd != "0000-00-00" && thirdEnd) ?
                                new Date(thirdEnd).toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" }) : t("program.Unknown")
                            }
                        </h5>
                        <span className="text-muted">{t("deadlineLabels.third")}</span>
                    </div>
                    : ""}
                {specialEnd ?
                    <div className="px-4 py-3 bg-light-hover">
                        <h5>
                            { (specialEnd != "0000-00-00" && specialEnd) ?
                                new Date(specialEnd).toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" }) : t("program.Unknown")
                            }
                        </h5>
                        <span className="text-muted">{t("deadlineLabels.special")}</span>
                    </div>
                    : ""}
            </div>
        );
    }

    return (
        <div id="fullWrapper" key={university+degree}>
            {userinfo(state => state.fromHomeOrSearch) === false ? <Popup setUserType={setUserType} /> : ""}
            {!isLoading ?
                <Error404 errorMessage={errorMessage} />
                :
                <>
                    <Helmet>
                        <title>Study Portugal - {values.university_data.name}, {values.name}</title>
                    </Helmet>
                    <Navibar homeArea={<Link to="/" className="text-dark text-decoration-none">Home</Link>}/>
                    <Map
                        initialViewState={{
                            longitude: values.yCoordinates,
                            latitude: values.xCoordinates,
                            zoom: 14
                        }}
                        style={{height: '32.5vh', minHeight: 220}}
                        mapboxAccessToken={process.env.REACT_APP_mapboxAccessToken}
                        mapStyle="mapbox://styles/mapbox/streets-v12"
                    />
                    <Container className="mt-5">
                        <Row>
                            <Col xs={12} sm={10} md={3} className="mb-1">
                                <div className="position-relative">
                                    <div id="picture" className="rounded shadow-sm position-absolute bg-white" style={{minWidth:'80%', zIndex: 20, backgroundImage:`url("${values.university_data.pictureURL}")`, backgroundSize:'cover', backgroundPosition: 'center', aspectRatio: '1 / 1', marginTop: '-85%'}}>

                                    </div>
                                    <div className="mt-5">
                                        <h5 className="bold">
                                            {values.university_data.name}
                                        </h5>
                                        <div className={"d-none d-md-block"}>
                                            <div className="mb-2">
                                                    <span className="sideLabel text-muted" style={{fontSize:'.9rem'}}>
                                                        {t('program.programTuition')}
                                                    </span>
                                                <br />
                                                <span className="bold">{
                                                    (userType === "EU citizen" ? "€" + parseFloat(values.tuition).toLocaleString()
                                                        : userType === "CPLP citizen" ? "€" + parseFloat(values.CPLPtuition).toLocaleString()
                                                            : userType === "Non-EU citizen" ? "€" + parseFloat(values.intTuition).toLocaleString() : t("program.Unknown")) !== '€0' ?

                                                        userType === "EU citizen" ? "€" + parseFloat(values.tuition).toLocaleString()
                                                            : userType === "CPLP citizen" ? "€" + parseFloat(values.CPLPtuition).toLocaleString()
                                                                : userType === "Non-EU citizen" ? "€" + parseFloat(values.intTuition).toLocaleString() : t("program.Unknown")
                                                        : t("program.Unknown")
                                                }</span>
                                            </div>
                                            <div className="mb-2">
                                                <span className="sideLabel text-muted" style={{fontSize:'.9rem'}}>
                                                    {t('program.programLanguage')}{(typeof values.tags !== 'undefined' && values.tags.length && i18n.language.toLowerCase().includes("en")) > 1 ? "s" : ""}
                                                </span>
                                                <br />
                                                <span className="bold">
                                                    {(typeof values.tags !== 'undefined' && values.tags.length) ? values.tags.filter(lang => lang.isLang).map(lang => lang.name).join(", ") : t("program.portuguese")}
                                                </span>
                                            </div>
                                            {values.university_data.name.toLowerCase().includes(values.city.toLowerCase()) ? "" :
                                                <div className="mb-3">
                                                    <span className="sideLabel text-muted" style={{fontSize:'.9rem'}}>
                                                        {t('program.city')}
                                                    </span>
                                                    <br />
                                                    <span className="bold">
                                                        {capitalizeFirstLetter(values.city)}
                                                    </span>
                                                </div>
                                            }
                                            <div className="mb-2">
                                                <div className="d-flex">
                                                    <div>
                                                        <a onClick={() => handleEventClick(values.link)} href={values.link} target="_blank" className="text-dark">
                                                            <Button variant="outline-success rounded-0">{t('program.viewProgram')}</Button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={9}>
                                <div className="mb-3 d-none d-md-block">
                                    <h3>
                                        {values.name}
                                    </h3>
                                </div>
                                <a href={values.link} target="_blank" className={"text-dark d-md-none text-decoration-none d-block mb-3"}>
                                    <span className={"h3"}>
                                        {location[2].replace(/%20/g, ' ')}&nbsp;
                                    </span>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                                <div
                                    className="bg-success border shadow-sm position-relative mb-4 w-100"
                                    style={{
                                        minHeight:'fit-content',
                                        marginTop: '10px',
                                        float: 'left',
                                        marginLeft: '-5px',
                                        marginRight: '5px'
                                    }}
                                >
                                    <div
                                        className="p-3 shadow-sm"
                                        style={{
                                            marginTop: '-5px',
                                            marginLeft: '5px',
                                            marginBottom: '5px',
                                            marginRight: '-5px',
                                            background: '#e6f7f5'
                                        }}
                                    >
                                        <Row>
                                            <Col className="text-center" xs={6} md={4}>
                                                <h5>{parseFloat(values.duration)} {t('program.years')}</h5>
                                                <span className="text-muted mobile-small">{t('program.duration')}</span>
                                            </Col>
                                            <Col className="text-center d-md-none" xs={6} md={4}>
                                                <h5>
                                                    {(typeof values.tags !== 'undefined'
                                                        && values.tags.length) ?
                                                        values.tags.filter(lang => lang.isLang).map(lang => lang.name).join(", ") : t("program.portuguese")
                                                    }
                                                </h5>
                                                <span className="text-muted mobile-small">{t('program.programLanguage')}{(typeof values.tags !== 'undefined' && values.tags.length && i18n.language.toLowerCase().includes("en")) > 1 ? "s" : ""}</span>
                                            </Col>
                                            <Col className="text-center position-relative d-flex justify-content-center mobile-mt-3" xs={6} md={4}>
                                                <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut} className="w-100" style={{height:'fit-content'}}>
                                                    { values.university_data.name !== "European University" ? typeof values.deadlines !== 'undefined' ?
                                                            isHovering ? <ExtendedDeadlines /> : <SmallDeadline />
                                                            : <SmallDeadline />
                                                        : <div className="position-relative">
                                                            <h5> {t("program.applyAnytime")} </h5>
                                                            <span className="text-muted mobile-small">{t('program.deadline')}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </Col>
                                            <Col className="text-center mobile-mt-3" xs={6} md={4}>
                                                <h5>
                                                    {values.startDate != "0000-00-00" ?
                                                        new Date(values.startDate).toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" })
                                                        : t("program.Unknown")
                                                    }
                                                </h5>
                                                <span className="text-muted mobile-small">{t('program.startDate')}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div>
                                    <div className="mt-4">
                                        <div className="mb-4">
                                            <Card className={"p-4 bg-white shadow"}>
                                                <h5>
                                                    {t('program.aboutProgram')}
                                                </h5>
                                                <span  className={"text-secondary"}>
                                                    {values.description ? values.description : t("program.noDescription")}
                                                </span>
                                                <hr className={"d-md-none"} />
                                                <Row>
                                                    <Col className={"text-center d-md-none"}>
                                                        <h5 className={"m-0"}>
                                                            {values.city}
                                                        </h5>
                                                        <span className="text-muted mobile-small"> {t('program.city')} </span>
                                                    </Col>
                                                    <Col className={"text-center d-md-none"}>
                                                        <h5 className={"m-0"}>
                                                            {
                                                                (userType === "EU citizen" ? "€" + parseFloat(values.tuition).toLocaleString()
                                                                    : userType === "CPLP citizen" ? "€" + parseFloat(values.CPLPtuition).toLocaleString()
                                                                        : userType === "Non-EU citizen" ? "€" + parseFloat(values.intTuition).toLocaleString() : t("program.Unknown")) !== '€0'
                                                                    ?
                                                                    (userType === "EU citizen" ? "€" + parseFloat(values.tuition).toLocaleString()
                                                                        : userType === "CPLP citizen" ? "€" + parseFloat(values.CPLPtuition).toLocaleString()
                                                                            : userType === "Non-EU citizen" ? "€" + parseFloat(values.intTuition).toLocaleString() : t("program.Unknown"))
                                                                    : t("program.Unknown")
                                                            }
                                                        </h5>
                                                        <span className="text-muted mobile-small"> {t("program.programTuition")} </span>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                        {values.applyUrl || values.applyEmail ?
                                            <ProgramPageApply values={values} />
                                            :""}
                                        {housing && !JSON.stringify(housing).includes('503 ') && housing.length  ? <Card className="mb-5 shadow pb-4" id={"splide-bay"}>
                                            <div className={"p-4 pb-3 d-flex align-items-center"}>
                                                <div className={"h5 mb-0"}>{t("program.nearbyHousing")}</div> &nbsp;&bull;&nbsp;
                                                <div className={"small mt-1"}>
                                                    <a
                                                        href={"https://www.uniplaces.com/?utm_source=StudyPortugal_affiliate&utm_medium=StudyPortugal_affiliate_affiliate_network&utm_campaign=StudyPortugal_affiliate_affiliate_network_prospecting_demand-xx_xx_xx_xx"}
                                                        target={"_blank"}
                                                        className={"text-decoration-none text-dark"}
                                                        onClick={() => handleEventClick("https://www.uniplaces.com/?utm_source=StudyPortugal_affiliate&utm_medium=StudyPortugal_affiliate_affiliate_network&utm_campaign=StudyPortugal_affiliate_affiliate_network_prospecting_demand-xx_xx_xx_xx")}
                                                    >
                                                        {t("program.uniPlacesLabel")}
                                                    </a>
                                                </div>
                                            </div>
                                            <Splide
                                                options={{
                                                    perPage: window.innerWidth < 993 ? 1 : 3,
                                                    padding: '1.5rem',
                                                }}
                                            >
                                                {housing ? housing.map(offer =>
                                                    <SplideSlide>
                                                        <a onClick={() => handleEventClick("https://www.uniplaces.com/accommodation/lisbon/"+offer.id+"?utm_source=StudyPortugal_affiliate&utm_medium=StudyPortugal_affiliate_affiliate_network&utm_campaign=StudyPortugal_affiliate_affiliate_network_prospecting_demand-xx_xx_xx_xx")} href={"https://www.uniplaces.com/accommodation/lisbon/"+offer.id+"?utm_source=StudyPortugal_affiliate&utm_medium=StudyPortugal_affiliate_affiliate_network&utm_campaign=StudyPortugal_affiliate_affiliate_network_prospecting_demand-xx_xx_xx_xx"} target={"_blank"} className={"text-decoration-none"}>
                                                            <div>
                                                                <div style={{width: "calc(100% - 1.25rem)"}} className={"rounded"}>
                                                                    <div
                                                                        className={"pe-2 rounded mb-2 w-100"}
                                                                        style={{
                                                                            height: "200px",
                                                                            backgroundImage: "url(https://cdn-static.staging-uniplaces.com/property-photos/"+offer.attributes.photos[0].hash+"/small.jpg)",
                                                                            backgroundPosition: "center center",
                                                                            backgroundSize: "cover"
                                                                        }}
                                                                        alt={t("program.uniPlacesImageAlt")}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={"bold mb-1 overflow-hidden text-dark splide-title-text"}
                                                                    style={{
                                                                        height: "3em",
                                                                        width: "calc(100% - 1rem)",
                                                                    }}
                                                                >
                                                                    {offer.attributes.accommodation_offer.title}
                                                                </div>
                                                                <div
                                                                    className={"small"}
                                                                    style={{color: "#036333"}}
                                                                >
                                                                    {t("program.from")} {new Date(offer.attributes.accommodation_offer.available_from).toLocaleDateString(i18n.language, { day: "numeric", month: "short", year: "numeric" })}
                                                                </div>
                                                                <div className={"text-dark"}>
                                                                    <span className={"h5"}>
                                                                        {offer.attributes.accommodation_offer.price.currency_code === "EUR" ? "€" : ""}
                                                                        {(offer.attributes.accommodation_offer.price.amount / 100).toFixed(2).toLocaleString()}
                                                                    </span>
                                                                    &nbsp;
                                                                    <span className={"small text-secondary"}>/{t("program.month")}</span>
                                                                    <span className={"small text-muted"}>&nbsp;&nbsp;&nbsp; {t("program.billsIncluded")}</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </SplideSlide>
                                                ): ""}
                                            </Splide>
                                        </Card>:''}
                                        <Card className={"mb-5 shadow p-4"}>
                                            <div className={"h5 mb-2"}>{t("program.similarPrograms")}</div>
                                            <Row>
                                                {suggestedPrograms ? suggestedPrograms.map((suggestedProgram, index) =>
                                                    <Col key={index} className={index < 2 ? "" : "d-none d-md-block"}>
                                                        <Link to={`/${suggestedProgram.university_data.name}/${suggestedProgram.name.replace("Degree", suggestedProgram.DegreeType)}`} className={"text-decoration-none"}>
                                                            <div className="border rounded shadow-sm my-3 bg-white" style={{width:'5rem', zIndex: 20, backgroundImage:`url("${suggestedProgram.university_data.pictureURL}")`, backgroundSize:'cover', backgroundPosition: 'center', aspectRatio: '1 / 1'}} />
                                                            <div className={"fw-semibold text-dark"} style={programNameListStyles.container}>{capitalizeFirstLetter(suggestedProgram.name.replace(t("degreeTypes.DegreeIn"), ""))}</div>
                                                            <div className={"d-flex justify-content-between"} style={{width: "80%"}}>
                                                                <div className={"text-secondary"}>
                                                                    {suggestedProgram.DegreeType === "LangP" ? t('program.languagePrograms') : t("degreeTypes."+suggestedProgram.DegreeType.replace(" ", "").replace("'", ""))}
                                                                    {suggestedProgram.tags.length ? " ("+t("degreeTypes.in")+" " + suggestedProgram.tags[0].name + ")" : ""}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                ) : ''}
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
            }
        </div>
    )
}

const programNameListStyles = {
    container: {
        height: "1.5rem",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    }
};