/**
 * Finds the dates in an object closest to today
 *
 * @param myObject Object with only dates
 * @returns {string} Date or null if all dates are in the past
 * @constructor
 */
export function ClosestToToday(myObject) {
    const today = new Date();
    let minDate = null;
    for (const key in myObject) {
        if (Object.hasOwnProperty.call(myObject, key)) {
            const value = myObject[key];

            // Check if the value is a valid date
            if (isNaN(Date.parse(value))) {
                continue;
            }

            const date = new Date(value);

            if (date > today && (minDate === null || date < minDate)) {
                minDate = date;
            }
        }
    }
    return minDate
}