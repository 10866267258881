import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Moment from "react-moment";
import {ClosestToToday} from "./closestToToday";
import React from "react";
import {useTranslation} from "react-i18next";

export default function ProgramItem(props) {
    const { i18n, t } = useTranslation();

    /**
     * @param {string} val The tuition cost
     * @returns {string} Returns the tuition cost either as unknown if 0, or with a € sign
     */
    function ifZeroUnknown(val) {
        if (val == 0) {
            return 'Unknown'
        } else {
            return '€'+val
        }
    }

    return (
        <Card className="shadow-sm p-2 mb-2" key={props.results.pk}>
            <Row className="mx-1">
                <div className="p-2 rounded d-flex" style={{width:'inherit'}}>
                    <Link to={`/${props.name}/${props.results.name.replace("Degree", props.results.DegreeType)}`}>
                        <div style={{minWidth:'7rem', backgroundImage:`url("${props.pictureURL}")`, backgroundSize:'cover', backgroundPosition: 'center', aspectRatio: '1 / 1'}} className="rounded border shadow-sm">

                        </div>
                    </Link>
                </div>
                <Col className="mt-2" md={7} xs={12}>
                    <Link to={`/${props.name}/${props.results.name.replace("Degree", props.results.DegreeType)}`} className="text-decoration-none">
                        <h5 className="text-dark">{!props.results.DegreeType.includes('LangP') && !props.results.DegreeType.includes('Foundation Year') ? t("degreeTypes." +props.results.DegreeType+"In") :''} {props.results.name.replace(t("degreeTypes.DegreeIn"), '')} </h5>
                        <div className="text-dark">
                            {props.name}
                        </div>
                    </Link>
                    <div className="w-100 mb-2 mt-2">
                        {(typeof props.results.tags !== 'undefined') ?
                            props.results.tags.length ?
                                props.results.tags.sort((a, b) => a.name.localeCompare(b.name)).map((tag) =>
                                    <div className="bg-success text-light rounded pointer mr-1 mt-1 d-inline-block fit-content" onClick={() => props.languageSwitcher(`${tag.name}`)} style={{padding:'.05rem'}}>&nbsp;{tag.name}&nbsp;</div>
                                )
                                :
                                <div className="bg-success text-light rounded pointer mr-1 fit-content" onClick={() => props.languageSwitcher(`Portuguese`)} style={{padding:'.05rem'}}>&nbsp;{t("languageOptions.Portuguese")}&nbsp;</div>
                            : <div className="bg-success text-light rounded pointer mr-1 fit-content" onClick={() => props.languageSwitcher(`Portuguese`)} style={{padding:'.05rem'}}>&nbsp;{t("languageOptions.Portuguese")}&nbsp;</div>}
                    </div>
                </Col>
                <Col md={3} xs={12} className="py-2" style={{paddingLeft: '.75rem'}}>
                    {
                        (props.descent == 1 || props.citizenship === "EU citizen") ?
                            ifZeroUnknown(parseFloat(props.results.tuition).toLocaleString()) : (props.citizenship == "CPLP citizen") ? ifZeroUnknown(parseFloat(props.results.CPLPtuition).toLocaleString()) : ifZeroUnknown(parseFloat(props.results.intTuition).toLocaleString())
                    } / {t("program.year")}
                    <br />
                    <span className="text-muted">{parseFloat(props.results.duration).toLocaleString()} {t("program.years")}</span>
                    <br />
                    {/*TODO: Check if past in future update <FontAwesomeIcon icon={faExclamationCircle} flip="horizontal" color="orange"/>*/}
                    {/*Account for CPLP in future update*/}
                    <span className="text-muted">
                        { props.name !== "European University" ?
                            // eslint-disable-next-line
                            (props.descent == 1 || props.citizenship === "EU citizen") ?
                                props.results.deadlines.filter(date => date.studentType === "local").length && ClosestToToday(props.results.deadlines.find(date => date.studentType === "local")) ?
                                    ClosestToToday(props.results.deadlines.find(date => date.studentType === "local")).toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" })
                                    :
                                    t("program.Unknown")
                                :
                                props.results.deadlines.filter(date => date.studentType === "international").length && ClosestToToday(props.results.deadlines.find(date => date.studentType === "international")) ?
                                    ClosestToToday(props.results.deadlines.find(date => date.studentType === "international")).toLocaleDateString(i18n.language, { day: "numeric", month: "long", year: "numeric" })
                                    :
                                    t("program.Unknown")
                            : t("program.applyAnytime")
                        }
                    </span>
                </Col>
            </Row>
        </Card>
    )
}