import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/home.css';
import {
  Dropdown,
  Button,
  InputGroup,
  FormControl,
  Spinner, Col, Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Image, Transformation } from 'cloudinary-react';
import Navibar from "./microComponents/navbar"; 
import {Link, useNavigate} from "react-router-dom";
import 'font-awesome/css/font-awesome.css';
import { Helmet } from "react-helmet";
import Twemoji from 'react-twemoji';
import '../css/global.css';
import {SimpleSearchAPI} from "./apis/search";
import {userinfo} from "../state/searchResults";
import {useTranslation} from "react-i18next";

function App() {
  const [citizenshipOptions, setCitizenshipOptions] = useState(["EU citizen", "CPLP citizen"]);
  const [activeVal, setActiveVal] = useState("Non-EU citizen");
  const [descent, setDescent] = useState(0);
  // Used for search spinner
  const [loading, setLoading] = useState(0);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * Lets the user switch their citizenship type
   *
   * @param {string} value Citizenship type selected by user
   */
  function changeActiveVal(value) {
      // Sets the citizenship options the user can click, ie, everything but the currently selected one.
      setCitizenshipOptions(prevCitizenship => prevCitizenship.filter(citizenshipOptions => citizenshipOptions !== value).concat(activeVal));

      // Sets the users citizenship status
      setActiveVal(value);

      // Resets if the user is of EU descent (which allows for local tuition)
      setDescent(0);

      // Saves citizenship status to state to be used on other pages
      userinfo.getState().saveState(value, 0, false)
  }

  /**
   * Searches for programs and redirects the user if successful
   *
   * @param {React.FormEvent<HTMLFormElement>} e Event (works on enter and clicking the submit btn)
   */
  const requestData = async function (e) {
    e.preventDefault()
    setLoading(1);
    const search = e.target.search.value;

    await SimpleSearchAPI(search).then(() => {
        navigate('/search/'+search);
    }).catch((e) => {
        setError(e.message)
    });

    e.preventDefault();
  }


  return (
      <>
        <center>
          <Helmet>
            <title>Study in Portugal | Study in Europe</title>
            <meta name="description" content="Find information on how to study in Portugal at any of over 2500+ programs at dozens of universities and polytechnics. From bachelors degrees, to language programs, to student visa information." />
          </Helmet>
          <Navibar homeArea={[t("home.welcome"), <Twemoji options={{ className: 'twemoji' }} style={{margin: '0 .25rem 0 .25rem'}}>🇵🇹</Twemoji>]} />

          <div className={"mx-md-4"}>
            <div className="min-vh-100 max-vw-100 d-flex text-left align-items-center">
              <div className="w-100 mx-5" style={{ marginTop: '7.5rem' }} id="megaWrapper">
                <div className="d-flex shadow-sm" style={{flex:1, flexDirection: 'row', position: 'relative', backgroundColor: '#046535', padding: '20px'}}>
                  <div className={"shadow-sm"} style={{padding: '87px 42px 87px 55px', flexBasis:'45%', backgroundColor: '#e6f7f5', marginTop:'-40px', zIndex: '2'}} id="wrapper">
                      <h1 className="position-relative bold">
                          {t("home.discoverPortugal")}
                      </h1>
                      <h3 className="mb-4">
                          {t("home.taglinePT1")} <br className={"d-none d-md-block"}/> {t("home.taglinePT2")}
                      </h3>
                      <div
                        className="d-flex mb-2"
                        id="personalFilter"
                      >
                        <h4 style={{whiteSpace:'nowrap', marginRight:'.25rem'}}>{t("home.ImA")}</h4>
                        <Dropdown drop="up" className="ml-2">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {activeVal}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {citizenshipOptions.map((citizenshipOptions) =>
                              <Dropdown.Item key={citizenshipOptions} onClick={() => changeActiveVal(citizenshipOptions)}>{citizenshipOptions}</Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                        { activeVal == "Non-EU citizen" &&
                        <Dropdown drop="up" className="mx-2" id="scnd">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {descent ? t("search.relative") : t("home.notRelative")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className="mx-3">
                                {t("home.tuitionNote")}
                              <hr />
                            </div>
                            <Dropdown.Item onClick={() => {
                                setDescent(descent ? 0 : 1);
                                userinfo.getState().saveState(citizenshipOptions, descent ? 0 : 1, false);
                            }}>{descent ? t("home.notRelative") : t("home.relative")}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        }
                      </div>
                      <form onSubmit={requestData}>
                        <input type="hidden" value={activeVal} name="citizenship" />
                        <input type="hidden" value={descent} name="descent" />
                        <InputGroup className="mb-3 shadow rounded border">
                          <FormControl
                            placeholder={t("home.searchADegree")}
                            aria-label="Search a degree"
                            aria-describedby="searchbtn"
                            className="p-2 border-0"
                            name="search"
                          />
                          <Button variant="secondary" className="border-0 bg-white" type="submit">
                            { loading ?
                            <Spinner animation="border" role="status" style={{color:'grey'}} className="d-flex align-items-center">
                              <span className="visually-hidden">{t("apply.loading")}</span>
                            </Spinner>
                            :
                              <FontAwesomeIcon icon={faSearch} flip="horizontal" color="grey"/>
                            }
                          </Button>
                        </InputGroup>
                      </form>
                      {error}
                  </div>
                  <div bg="dark" className="d-flex" style={{position: 'absolute', top: '-60px', alignItems:'center', width:'70%', height:'calc(100% + 100px)', right: '0', padding: 'inherit', objectFit:'cover'}} id="image">
                      <Image cloudName="studyportugal-pt" className="shadow h-100 w-100 expandable" publicId="castle" alt="Sintra, Portugal background image">
                        <Transformation fetchFormat="auto" />
                      </Image>
                      <div style={{position:'absolute', bottom: '1.5rem', right: '25px', opacity: '.7'}} className="bg-light rounded" id="tooltip">
                        &nbsp;{t("home.sintra")}&nbsp;
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </center>
        <div className={"bg-light py-5 shadow-sm"} style={{marginTop: '-1rem'}}>
          <div className={"mx-sm-5"}>
            <Row>
              <Col className={"mb-5"} sm={6} lg={4}>
                  <Link to={"/search/All/English"} className={"text-decoration-none"}>
                    <div className={"position-relative mx-5 h-100"}>
                      <div
                          className={"p-2 bg-white shadow rounded-0 mx-2 h-100 d-flex align-items-center"}
                          style={{ position: 'relative', zIndex: '1'}}
                      >
                        <div className={"ms-3 p-2"}>
                          <div>
                            <Twemoji options={{ className: 'twemoji mb-2 me-2'}} className={"d-inline-block h3 mb-0"}>
                              🇬🇧
                            </Twemoji>
                            <span className={"h4 mb-0 text-dark"}> {t("home.studyInEnglish")}</span>
                          </div>
                          <div className={"text-secondary"}>
                            {t("home.studyInEnglishSubtext")}
                          </div>
                        </div>
                      </div>
                      <div
                          className={"shadow-sm"}
                          style={{
                            background: '#046535',
                            position: 'absolute',
                            top: '.5rem',
                            left: '0',
                            width: 'calc(100% - 1rem)',
                            height: '100%',
                            zIndex: '0'
                          }}
                      />
                    </div>
                  </Link>
              </Col>
              <Col className={"mb-5"} sm={6} lg={4}>
                  <Link to={"/search/All/all/langP"} className={"text-decoration-none"}>
                    <div className={"position-relative mx-5 h-100"}>
                      <div
                          className={"p-2 bg-white shadow rounded-0 mx-2 h-100 d-flex align-items-center"}
                          style={{ position: 'relative', zIndex: '1'}}
                      >
                        <div className={"ms-3 p-2"}>
                          <div>
                            <Twemoji options={{ className: 'twemoji mb-2 me-2' }} className={"d-inline-block h3 mb-0"}>
                              🇵🇹
                            </Twemoji>
                            <span className={"h4 mb-0 text-dark"}> {t("home.learnPortuguese")}</span>
                          </div>
                          <div className={"text-secondary"}>
                              {t("home.learnPortugueseSubtext")}
                          </div>
                        </div>
                      </div>
                      <div
                          className={"shadow-sm"}
                          style={{
                            background: '#046535',
                            position: 'absolute',
                            top: '.5rem',
                            left: '0',
                            width: 'calc(100% - 1rem)',
                            height: '100%',
                            zIndex: '0'
                          }}
                      />
                    </div>
                  </Link>
              </Col>
                <Col className={"mb-5"} sm={6} lg={4}>
                    <a href={"https://www.uniplaces.com/?utm_source=StudyPortugal_affiliate&utm_medium=StudyPortugal_affiliate_affiliate_network&utm_campaign=StudyPortugal_affiliate_affiliate_network_prospecting_demand-xx_xx_xx_xx"} className={"text-decoration-none"} target={"_blank"}>
                        <div className={"position-relative mx-5 h-100"}>
                            <div
                                className={"p-2 bg-white shadow rounded-0 mx-2 h-100 d-flex align-items-center"}
                                style={{ position: 'relative', zIndex: '1'}}
                            >
                                <div className={"ms-3 p-2"}>
                                    <div className={"mb-1 d-flex align-items-center"}>
                                        <span className={"h4 mb-0 text-dark"}>
                                            {t("home.housing")}
                                        </span>
                                        <div className={"ms-2"}>
                                            <small className={"text-secondary"}>
                                                {t("home.sponsored")}
                                            </small>
                                        </div>
                                    </div>
                                    <div className={"text-secondary"}>
                                        {t("home.housingSubtext")}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={"shadow-sm"}
                                style={{
                                    background: '#046535',
                                    position: 'absolute',
                                    top: '.5rem',
                                    left: '0',
                                    width: 'calc(100% - 1rem)',
                                    height: '100%',
                                    zIndex: '0'
                                }}
                            />
                        </div>
                    </a>
                </Col>
            </Row>
          </div>
        </div>
      </>
  );
}

export default App;
