import axios from "axios";
import {apiProtocol, apiurl} from "../microComponents/urls";
import {query} from "../../state/searchResults";
import i18n from "i18next";
import {SupportedLanguages} from "../microComponents/supportedLanguages";


/**
 * Search API that returns program info with start, end, lang, or degree parameters as filters.
 *
 * @param setResults Function to set results too
 * @param {string} citizenship Stores user parameters for citizenship to state as bool
 * @param {number} descent  Stores user parameters for descent to state as bool
 * @param {string} search String user query
 * @param {string} lang Language of the programs the user is searching for. Filters results.
 * @param {string} degr Degree level, without apostrophes (Bachelors, masters, etc) of the programs the user is searching for. Filters results.
 * @param {number} start Row number to start at in results
 * @param {number} end Row number to end at in results
 * @param {boolean} concat Whether to return the results for them to be concat
 */
export async function ComplexSearchAPI(setResults, citizenship, descent, search, lang, degr, start=0, end=10, concat=false) {
    const langFormatter = i18n.language.includes("-") ? i18n.language.split('-')[0] : i18n.language;
    const userLanguage = SupportedLanguages().includes(langFormatter) ? "/" + langFormatter : "";
    // Returns whether the search succeeded or not
    try {
        let res = await axios.get(apiProtocol + apiurl + '/search/' + search.trim() + '/' + start + '/' + end + '/' + lang + '/' + degr + userLanguage);

        if (!concat) {
            setResults(res.data);
            query.setState({ search: search, results: res.data });

        } else {
            return res.data
        }

    } catch (e) {
        // Don't expose the error to the user in prod
        console.log("Search failed", e)
        throw new Error(process.env.NODE_ENV === "development" ? e.message: 'Search failed');
    }
    return false
}


