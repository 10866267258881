import Form from 'react-bootstrap/Form';
import {userinfo} from "../../state/searchResults";

export function Popup(props) {

    /**
     * Converts user citizenship type to the standard format to what state uses and saves
     *
     * @param {React.FormEvent<HTMLDivElement>} e
     */
    const userData = (e) => {
        let val2;
        let val = e.target.value

        // Return userType callback in correct format
        if (val === "cplp")  {
            val2 = "CPLP citizen";
        } else if (val === "descendent" || val === "citizenship") {
            val2 = "local";
        } else {
            val2 = "international";
        }
        props.setUserType(val2)

        userinfo.setState({
            [val]: 1,
            fromHomeOrSearch: true
        })
    }

    return (
        <div
            className={"bg-white position-fixed border bottom-0 p-3 shadow-sm rounded-top"}
            style={{zIndex: 5, right: "1rem"}}
            onChange={userData}
        >
            <div className={"mb-2"}>
                <div className={"h5 mb-0"}>
                    Are you a EU or CPLP Citizen?
                </div>
                <div className={"text-secondary"}>
                    Deadlines and costs may differ
                </div>
            </div>
            <div className="mb-3">
                <Form.Check
                    label="International"
                    name="group1"
                    type={"radio"}
                    id={"citizen"}
                    value={"international"}
                />
                <Form.Check
                    label="EU Citizen"
                    name="group1"
                    type={"radio"}
                    id={"eucitizen"}
                    value={"citizenship"}
                />
                <Form.Check
                    label="CPLP Citizen"
                    type={"radio"}
                    name="group1"
                    id={"cplp"}
                    value={"cplp"}
                />
                <Form.Check
                    label="Portuguese Descent"
                    name="group1"
                    type={"radio"}
                    id={"descent"}
                    value={"descendent"}
                />
            </div>
        </div>
    )
}