import axios from "axios";
import {apiProtocol, apiurl} from "../microComponents/urls";

/**
 * Shows similar program suggestions related to the open program on a program page.
 *
 * @param degreeName Name of degree, as in "Degree in Psychology"
 * @param degreeType Type of degree
 * @param pk Program pk
 * @param tags Any tags associated with the program. This will weigh the vector search to find more similar programs
 */
export async function similarPrograms(degreeName, degreeType, pk, tags=[], language="EN",) {
    tags = tags.map(tag => tag.name);
    // Returns whether the search succeeded or not
    try {
        return (await axios.post(apiProtocol + apiurl + '/similar-programs/' + degreeName.trim() + '/' + degreeType.trim() + '/' + language, {
            tags: tags,
            pk: pk
        })).data
    } catch (e) {
        // Don't expose the error to the user in prod, and fail silently as this' non-essential
        if (process.env.NODE_ENV === "development") {
            throw new Error(e.message)
        }
    }
}