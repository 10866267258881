import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Routes,
    Route, useLocation, redirect,
} from "react-router-dom";
import Home from './components/home';
import Search from './components/search';
import Faq from './components/faq';
import Program from './components/program';
import Contact from './components/contact';
import ReactGA from "react-ga4";
import University from "./components/university";
import Programs from "./components/programs";
import Privacy from "./privacy";
import i18n from "i18next";
import {SupportedLanguages} from "./components/microComponents/supportedLanguages";

export default function App() {
  return (
      <Router>
          <AppWrapper />
      </Router>
  )
}


function AppWrapper() {
  ReactGA.initialize("G-WC808F6011");
  const langFormatter = i18n.language.includes("-") ? i18n.language.split('-')[0] : i18n.language;
  const [baseUrl, setBaseUrl] = useState(langFormatter === 'en' || !(SupportedLanguages().indexOf(langFormatter) > -1) ? '' : '/' + langFormatter);
  const location = useLocation();
  const path = location.pathname;
  const loc = path.split('/');

  if (process.env.NODE_ENV === "production") console.log = function no_console() {};

  useEffect(() => {
      console.log(i18n.language);

      if (SupportedLanguages().includes(loc[1]) && langFormatter !== 'en' && loc[1] !== langFormatter && SupportedLanguages().includes(i18n.language)) {
        console.log("Redirected user from one supported language to other")
        // redirects user from one supported language to other, if they arrive from language-specific url and are of other supported lang
        window.location.replace(path.replace(loc[1], langFormatter));
      } else if ((langFormatter === 'en' && SupportedLanguages().includes(loc[1])) || (!SupportedLanguages().includes(langFormatter) && SupportedLanguages().includes(loc[1]))) {
        console.log("Redirected user to default (English)")
        // redirects user to english if user language is english or not supported, but user arrives from a lang url
        window.location.replace(window.location.pathname.replace("/" + loc[1],""));
      } else if (!window.location.pathname.startsWith(baseUrl)) {
          // Redirect to the correct language baseUrl if not already there
          window.location.replace(baseUrl + window.location.pathname);
      }
  }, [location.pathname, baseUrl]);

  return (
        <Routes>
          <Route path={baseUrl + "/:university/:degree"} element={<Program baseUrl={baseUrl} />} />
          <Route path={baseUrl + "/faq"} element={<Faq />} />
          <Route path={baseUrl + "/privacy"} element={<Privacy />} />
          <Route path={baseUrl + "/institutions"} element={<University />} />
          <Route path={baseUrl + "/institutions/:u/programs"} element={<Programs />} />
          <Route path={baseUrl + "/contact"} element={<Contact />} />
          <Route path={baseUrl + "/search/:s"} element={<Search />}>
            <Route path=":l" element={<Search />}>
              <Route path=":p" element={<Search />} />
            </Route>
          </Route>
          {/* Redirect root to the appropriate base URL */}
          <Route path={"/" + baseUrl} element={<Home />} />
        </Routes>
  );
}