import React from 'react';
import {Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

function Error404(props) {
    const { t } = useTranslation();
    return (
        <div className="position-absolute w-100 h-100 bg-light d-flex justify-content-center align-items-center"
             style={{zIndex: "2000"}}>
            {!props.errorMessage ?
                <Spinner animation="border" role="status" style={{color: 'grey'}} className="d-flex align-items-center">
                    <span className="visually-hidden">{t("apply.Loading")}</span>
                </Spinner>
                :
                <div className={"text-center"}>
                    <FontAwesomeIcon icon={faExclamationTriangle} className={"link-secondary"}/>
                    <br/>
                    <div className={"text-secondary"}>{t("search.error404")}</div>
                </div>
            }
        </div>
    );
}

export default Error404;